.sidebarTab {
    font-size: 1.3rem;
    height: auto;
    display: flex;
    cursor: pointer;
    justify-content: center;
}

@media(min-width: 992px) {
    .sidebarTab {
        justify-content: end;
    }
}

.sidebarTab > a {
    text-decoration: none;
    color: #fff;
    text-align: center;
    padding: 0 5px;
}

.active {
    background-color: #fff;
    border-radius: 0 0 35px 35px;
}

.sidebarTab.active > a{
    color: #333;
}

@media(min-width: 992px) {
    .sidebarTab > a {
        margin-right: 10px;
    }

    .active {
        background-color: #fff;
        border-radius: 0 0 0 35px;
        width: 185px;
        margin-left: 10px;
    }
}