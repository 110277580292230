.shiftDetails > span {
    width: 10%;
}

.shiftDetails {
    cursor: pointer;
    color: blue;
    display: flex;
    justify-content: space-evenly;
}

.deliveryWrapper > span {
    padding: 0 5px;
}

.listItem {
    border: 1px solid black;
}