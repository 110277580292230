.statsRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.detailBlock {
    width: 100%;
    border: 1px solid #333;
    border-radius: 5px;
}

@media(min-width: 992px) {
    .detailBlock {
        width: 50%;
        margin: auto;
    }
}

.statWrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 10px auto;
}

.statWrapper span:nth-child(1) {
    font-size: 24px;
}

.statWrapper span:nth-child(2) {
    font-size: 20px;
}

.chartsRow {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}