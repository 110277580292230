.sidebar {
    position: fixed;
    width: 100%;
    background-color: rgb(235, 23, 0);
    z-index: 2;
    display: flex;
    top: 0;
    left: 0;
    height: 3rem;
    justify-content: space-evenly;
}

@media(min-width: 992px) {
    .sidebar {
        width: 195px;
        min-height: 100vh;
        z-index: 2;
        flex-direction: column;
        justify-content: flex-start;
    }
}

.headerBrand {
    display: flex;
    justify-content: end;
    margin-right: 10px;
}

.headerBrand a {
    font-size: 2rem;
    color: #fff;
    text-decoration: none;
}

.headerBrand a:hover {
    opacity: 0.8;
}