.navbar {
    background-color: rgb(235, 23, 0);
    color: #fff;
    font-size: 1rem;
    line-height: 2rem;
    position: fixed;
    top: 3rem;
    left: 0;
    width: 100%;
    height: 2rem;
    z-index: 1;
}

@media(min-width: 992px) {
    .navbar {
        top: 0;
        left: 195px;
    }
}